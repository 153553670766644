














































import { Component, Inject, Prop } from 'vue-property-decorator'

import { AllowedImageRatio, ProductData } from '../../../../../contexts'

import { AddedToCartModalMixin } from '../../../../shared/mixins/addedToCartModal.mixin'
import { CartItem } from '../../../../checkout/molecules/CartItem'
import { Loader } from '../../../../shared/molecules/Loader'
import { toImageProps } from '../../../../shared/support'
import { ProductCard } from '../../../../products/organisms/ProductCard'
import {
  translateProductToProductCard
} from '../../../../products/organisms/ProductCard/ProductCard.helpers'

import { CartItemProps } from '../../../molecules/CartItem/CartItem.contracts'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Rich>({
  name: 'Rich',
  components: {
    CartItem,
    Loader,
    ProductCard
  }
})
export class Rich extends AddedToCartModalMixin {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Boolean, required: true })
  public isLoading!: boolean

  @Prop({ type: Array, required: true, default: [] })
  public suggestedProducts!: Array<ProductData>

  public get image () {
    return toImageProps(this.payload.image, AllowedImageRatio.Square)
  }

  public onProductCardClick (): void {
    this.close()
  }

  public get productItem (): CartItemProps {
    return {
      description: this.payload.product.title ?? '',
      price: this.payload.product.price,
      previousPrice: this.payload.product.previousPrice,
      thumbnail: this.payload.product.image,
      title: this.payload.product.brand ?? '',
      enhancedDesc: `${this.payload.product.category ?? ''} ${this.payload.product.volume ?? ''}`,
      properties: [],
      isDisabled: true
    }
  }

  public get shownProducts (): Array<ProductData> {
    if (this.isMobile()) {
      return this.suggestedProducts.slice(0, 2)
    }

    return this.suggestedProducts.slice(0, 4)
  }

  public translateProductToProductCard = translateProductToProductCard
}

export default Rich
